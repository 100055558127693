@font-face {
    font-family: 'Klavika-Regular';
    src: url('./res/font/klavika-medium.otf') format('opentype');
}

/*
Font style should be Klavika for the entire web app
*/

* {
    font-family: 'Klavika-Regular', sans-serif;   
}